import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import Plane from './objects/plane';
import Sphere from './objects/sphere';
import Ring from './objects/ring';

import imgPath1 from '../../assets/la-mina.jpg';
import imgPath2 from '../../assets/la-mina-strip-orange.jpg';
import imgPath3 from '../../assets/la-mina-strip-black.jpg';

export default class {
  constructor() {
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1.5));
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.setClearColor('white', 1);

    this.camera = new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    this.camera.position.set(0, 0, 3.5);

    this.scene = new THREE.Scene();

    this.canvas = null;

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enableDamping = true;
    this.controls.maxDistance = 6;
    this.controls.minDistance = 2;

    this.clock = new THREE.Clock();

    this.loader = new THREE.TextureLoader();

    this.textures = [];
    this.objects = [];

    this.loadTextures([imgPath1, imgPath2, imgPath3]);
  }

  init() {
    this.addCanvas();
    this.addEvents();
    this.addElements();
    this.animate();    
  }

  async loadTextures(imgs) {
    const promises = [];

    imgs.forEach(img => {
      const promise = new Promise ((resolve, reject) => {
        const onLoad = texture => {
          texture.minFilter = THREE.NearestFilter;          
          resolve (texture);
        };
        
        const onError = event => reject (event);

        this.loader.load(img, onLoad, undefined, onError);
      });

      promises.push(promise);
    });

    this.textures = await Promise.all(promises);
    this.init();
    document.body.classList.remove('hidden');
  }

  addElements() {
    const plane = new Plane(this.textures[0]);
    const sphere = new Sphere(this.textures[1]);
    const ring = new Ring(this.textures[2]);

    this.objects.push(plane, sphere, ring);

    this.scene.add(this.objects[0]);
  }

  addCanvas() {
    this.canvas = this.renderer.domElement;
    this.canvas.classList.add('webgl');
    document.body.appendChild(this.canvas);
  }

  addEvents() {
    window.addEventListener('resize', this.resize.bind(this));

    window.addEventListener('keydown', ({ key }) => {
      switch (key) {
        case '1':
          this.removeObjects();
          this.removeClasses();
          this.renderer.setClearColor('white', 1);
          this.controls.reset()
          this.controls.maxDistance = 6;
          this.controls.minDistance = 2;          
          this.camera.position.set(0, 0, 3.5);
          this.scene.add(this.objects[0]);
          break;

        case '2':
          this.removeObjects();
          this.removeClasses();
          this.renderer.setClearColor('#EF5B2A', 1);
          this.controls.reset()
          this.controls.maxDistance = 10;
          this.controls.minDistance = null;          
          this.camera.position.set(0, 0, 4.75);
          this.scene.add(this.objects[1]);
          document.body.classList.add('orange');
          break;

        case '3':
          this.removeObjects();
          this.removeClasses();
          this.renderer.setClearColor('black', 1);
          this.controls.reset()
          this.controls.maxDistance = 50;
          this.controls.minDistance = null;          
          this.camera.position.set(0, 0, 3.5);
          this.scene.add(this.objects[2]);
          document.body.classList.add('black');
          break;
      }
    });

    window.addEventListener('dblclick', () => {
      if (!document.fullscreenElement) {
        this.canvas.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    });
  }

  removeObjects() {
    this.scene.children.forEach(obj => {
      this.scene.remove(obj);
    });
  }

  removeClasses() {
    document.body.classList.remove('orange');
    document.body.classList.remove('black');
  }

  resize() {
    let width = window.innerWidth;
    let height = window.innerHeight;

    this.camera.aspect = width / height;
    this.renderer.setSize(width, height);

    this.camera.updateProjectionMatrix();
  }

  animate() {
    requestAnimationFrame(this.animate.bind(this));
    this.render();
  }

  render() {
    this.controls.update();

    this.scene.children.forEach(obj => obj.material.uniforms.uTime.value = this.clock.getElapsedTime());

    this.renderer.render(this.scene, this.camera);
  }
}