import * as THREE from 'three';

import vertexShader from './vertex.glsl';
import fragmentShader from './fragment.glsl';

export default class Ring extends THREE.Object3D {
  constructor(texture) {
    super();

    this.geometry = new THREE.TorusBufferGeometry(10, 3, 126, 126);
    this.material = new THREE.ShaderMaterial({
      vertexShader,
      fragmentShader,
      uniforms: {
        uTime: { value: 0 },
        uTexture: { value: texture },
      },
      defines: {
        PI: Math.PI,
      },
      transparent: true,
      side: THREE.DoubleSide,
      // wireframe: true,
    });    

    this.mesh = new THREE.Mesh(this.geometry, this.material);
    this.mesh.position.set(0, -8, 0);
    this.mesh.rotation.set(0, Math.PI / 2, 0);

    this.add(this.mesh);
  }
}